@mixin code-injections {
  .footer-cta.outer {
    display: none;
  }
  .author-avatar.author-profile-image {
    display: none;
  }
  .article-byline-content .author-list {
    padding: 0;
  }
  .article-byline-meta {
    display: flex;
  }
  .article-byline-meta .author-name {
    margin-right: 8px;
  }
  .site-footer.outer {
    padding-bottom: 32px;
  }
  .gh-social {
    display: none;
  }
  .gh-head-button.gh-portal-close {
    display: none
  }
  .site-footer.outer {
    display: none
  }
  .article-byline-meta {
    margin-left: 10px
  }
}
