@mixin ghost-screen {
  a,abbr,acronym,address,applet,article,aside,audio,big,blockquote,body,canvas,caption,cite,code,dd,del,details,dfn,div,dl,dt,em,embed,fieldset,figcaption,figure,footer,form,h1,h2,h3,h4,h5,h6,header,hgroup,html,iframe,img,ins,kbd,label,legend,li,mark,menu,nav,object,ol,output,p,pre,q,ruby,s,samp,section,small,span,strike,strong,sub,summary,sup,table,tbody,td,tfoot,th,thead,time,tr,tt,ul,var,video {
    border: 0;
    font: inherit;
    font-size: 100%;
    margin: 0;
    padding: 0;
    vertical-align: baseline
  }

  body {
    line-height: 1
  }

  ol,ul {
    list-style: none
  }

  blockquote,q {
    quotes: none
  }

  blockquote:after,blockquote:before,q:after,q:before {
    content: "";
    content: none
  }

  img {
    display: block;
    height: auto;
    max-width: 100%
  }

  html {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    box-sizing: border-box;
    font-family: sans-serif
  }

  *,:after,:before {
    box-sizing: inherit
  }

  a {
    background-color: transparent
  }

  a:active,a:hover {
    outline: 0
  }

  b,strong {
    font-weight: 700
  }

  dfn,em,i {
    font-style: italic
  }

  h1 {
    font-size: 2em;
    margin: .67em 0
  }

  small {
    font-size: 80%
  }

  sub,sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline
  }

  sup {
    top: -.5em
  }

  sub {
    bottom: -.25em
  }

  img {
    border: 0
  }

  svg:not(:root) {
    overflow: hidden
  }

  mark {
    background-color: #fdffb6
  }

  code,kbd,pre,samp {
    font-family: monospace,monospace;
    font-size: 1em
  }

  kbd {
    background: #f6f8fa;
    border: 1px solid rgba(124,139,154,.25);
    border-radius: 6px;
    box-shadow: inset 0 -1px 0 rgba(124,139,154,.25);
    font-family: var(--font-mono);
    font-size: 1.5em;
    padding: 3px 5px
  }

  @media (max-width: 600px) {
    kbd {
      font-size:1.3em
    }
  }

  button,input,optgroup,select,textarea {
    color: inherit;
    font: inherit;
    margin: 0
  }

  button {
    border: none;
    overflow: visible
  }

  button,select {
    text-transform: none
  }

  button,html input[type=button],input[type=reset],input[type=submit] {
    -webkit-appearance: button;
    cursor: pointer
  }

  button[disabled],html input[disabled] {
    cursor: default
  }

  button::-moz-focus-inner,input::-moz-focus-inner {
    border: 0;
    padding: 0
  }

  input {
    line-height: normal
  }

  input:focus {
    outline: none
  }

  input[type=checkbox],input[type=radio] {
    box-sizing: border-box;
    padding: 0
  }

  input[type=number]::-webkit-inner-spin-button,input[type=number]::-webkit-outer-spin-button {
    height: auto
  }

  input[type=search] {
    -webkit-appearance: textfield;
    box-sizing: content-box
  }

  input[type=search]::-webkit-search-cancel-button,input[type=search]::-webkit-search-decoration {
    -webkit-appearance: none
  }

  legend {
    border: 0;
    padding: 0
  }

  textarea {
    overflow: auto
  }

  table {
    border-collapse: collapse;
    border-spacing: 0
  }

  td,th {
    padding: 0
  }

  html {
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    font-size: 62.5%
  }

  body {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -moz-font-feature-settings: "liga" on;
    background: #fff;
    color: var(--color-darkgrey);
    font-family: var(--font-sans);
    font-size: 1.6em;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 1.6em
  }

  ::-moz-selection {
    background: #daf2fd;
    text-shadow: none
  }

  ::selection {
    background: #daf2fd;
    text-shadow: none
  }

  hr {
    border: 0;
    border-top: 1px solid #f0f0f0;
    display: block;
    height: 1px;
    margin: 2.5em 0 3.5em;
    padding: 0;
    position: relative;
    width: 100%
  }

  audio,canvas,iframe,img,svg,video {
    vertical-align: middle
  }

  fieldset {
    border: 0;
    margin: 0;
    padding: 0
  }

  textarea {
    resize: vertical
  }

  ::not(.gh-content) blockquote,::not(.gh-content) dl,::not(.gh-content) ol,::not(.gh-content) p,::not(.gh-content) ul {
    margin: 0 0 1.5em
  }

  ol,ul {
    padding-left: 1.3em;
    padding-right: 1.5em
  }

  ol ol,ol ul,ul ol,ul ul {
    margin: .5em 0 1em
  }

  ul {
    list-style: disc
  }

  ol {
    list-style: decimal
  }

  ol,ul {
    max-width: 100%
  }

  li {
    line-height: 1.6em;
    padding-left: .3em
  }

  li+li {
    margin-top: .5em
  }

  dt {
    color: #daf2fd;
    float: left;
    font-weight: 500;
    margin: 0 20px 0 0;
    text-align: right;
    width: 120px
  }

  dd {
    margin: 0 0 5px;
    text-align: left
  }

  blockquote {
    border-left: #daf2fd;
    margin: 1.5em 0;
    padding: 0 1.6em
  }

  blockquote small {
    display: inline-block;
    font-size: .9em;
    margin: .8em 0 .8em 1.5em;
    opacity: .8
  }

  blockquote small:before {
    content: "\2014 \00A0"
  }

  blockquote cite {
    font-weight: 700
  }

  blockquote cite a {
    font-weight: 400
  }

  a {
    color: #15171a;
    text-decoration: none
  }

  h1,h2,h3,h4,h5,h6 {
    text-rendering: optimizeLegibility;
    font-weight: 600;
    letter-spacing: -.01em;
    line-height: 1.15;
    margin-top: 0
  }

  h1 {
    font-size: 4.8em;
    font-weight: 700;
    letter-spacing: -.015em;
    margin: 0 0 .5em
  }

  @media (max-width: 600px) {
    h1 {
      font-size:2.8em
    }
  }

  h2 {
    font-size: 2.8em;
    font-weight: 700;
    margin: 1.5em 0 .5em
  }

  @media (max-width: 600px) {
    h2 {
      font-size:2.3em
    }
  }

  h3 {
    font-size: 2.4em;
    font-weight: 600;
    margin: 1.5em 0 .5em
  }

  @media (max-width: 600px) {
    h3 {
      font-size:1.7em
    }
  }

  h4 {
    font-size: 2em;
    margin: 1.5em 0 .5em
  }

  @media (max-width: 600px) {
    h4 {
      font-size:1.7em
    }
  }

  h5 {
    font-size: 2em
  }

  h5,h6 {
    margin: 1.5em 0 .5em
  }

  h6 {
    font-size: 1.8em
  }

  :root {
    --color-green: #a4d037;
    --color-yellow: #fecd35;
    --color-red: #f05230;
    --color-darkgrey: #15171a;
    --color-midgrey: #738a94;
    --color-lightgrey: #f1f1f1;
    --color-secondary-text: #979797;
    --color-border: #e1e1e1;
    --color-wash: #e5eff5;
    --color-darkmode: #151719;
    --font-sans: -apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
    --font-serif: Georgia,Times,serif;
    --font-mono: Menlo,Courier,monospace
  }

  .viewport {
    display: flex;
    flex-direction: column;
    min-height: 100vh
  }

  .site-content {
    flex-grow: 1
  }

  .outer {
    padding: 0 max(4vmin,20px);
    position: relative
  }

  .inner {
    margin: 0 auto;
    max-width: 1200px;
    width: 100%
  }

  .site-header {
    background: var(--ghost-accent-color);
    color: #fff;
    position: relative
  }

  .site-header-cover {
    bottom: 0;
    height: 100%;
    left: 0;
    -o-object-fit: cover;
    object-fit: cover;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%
  }

  .site-header-content {
    align-items: center;
    color: var(--color-darkgrey);
    display: flex;
    padding-bottom: 6.4vmin;
    padding-top: 6.4vmin;
    position: relative;
    text-align: center;
    z-index: 100
  }

  .has-cover .site-header-content {
    background-color: var(--ghost-accent-color);
    color: #fff;
    min-height: 560px
  }

  body:not(.has-cover) .site-header-content:not(.left-aligned) {
    padding-bottom: max(4.8vmin,24px)
  }

  .site-header-content.left-aligned {
    padding-bottom: 0;
    text-align: left
  }

  .has-cover .site-header-content.left-aligned {
    align-items: flex-end;
    padding-bottom: 6.4vmin
  }

  .site-header-content.no-content {
    padding-top: 0
  }

  .site-header-inner {
    position: relative
  }

  .site-header-content.left-aligned .site-header-inner {
    align-items: flex-start
  }

  .site-logo {
    flex-shrink: 0;
    margin: 0 auto;
    max-height: 120px
  }

  .site-header-content.left-aligned .site-logo {
    margin-left: 0;
    margin-right: auto;
    max-height: 96px
  }

  .site-title {
    font-size: 5em;
    font-weight: 800;
    margin: 0;
    padding: 0;
    z-index: 10
  }

  .has-serif-title .site-title {
    font-family: var(--font-serif)
  }

  .has-cover .site-title {
    font-size: 6em
  }

  .site-header-content.left-aligned .site-title {
    font-size: 4.4em
  }

  .has-cover .site-header-content.left-aligned .site-title {
    font-size: 4.6em
  }

  .site-description {
    display: inline-block;
    font-size: 2.4em;
    font-weight: 500;
    line-height: 1.4;
    max-width: 560px;
    z-index: 10
  }

  :is(.site-logo,.site-title)+.site-description {
    font-size: 2.1em;
    font-weight: 400;
    margin-top: 8px
  }

  .site-logo+.site-description {
    margin-top: 20px
  }

  .site-title+.site-description {
    color: var(--color-secondary-text)
  }

  .has-cover .site-description {
    color: #fff;
    font-size: 3.2em;
    letter-spacing: -.005em
  }

  .has-cover :is(.site-logo,.site-title)+.site-description {
    font-size: 2.4em
  }

  .has-cover .site-header-content.left-aligned :is(.site-logo,.site-title)+.site-description {
    font-size: 2.2em
  }

  @media (max-width: 767px) {
    .has-cover .site-header-content {
      min-height:240px
    }

    .site-header-inner {
      gap: 16px
    }

    .site-logo {
      max-width: 60%
    }

    .site-title {
      font-size: 3.4em!important
    }

    .site-description {
      font-size: 1.9em!important
    }

    .site-logo+.site-description {
      margin-top: 12px!important
    }

    .site-title+.site-description {
      margin-top: 4px!important
    }
  }

  .gh-head {
    background-color: #fff;
    font-size: 1.6em;
    line-height: 1.3em;
    padding-bottom: min(24px,3.6vmin);
    padding-top: min(24px,3.6vmin)
  }

  .has-cover:not(.home-template) .gh-head {
    background-color: var(--ghost-accent-color);
    color: #fff
  }

  .home-template.has-cover .gh-head {
    background-color: transparent;
    color: #fff;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2000
  }

  .gh-head a {
    text-decoration: none
  }

  .gh-head-inner {
    grid-gap: 40px;
    display: grid;
    grid-auto-flow: row dense;
    grid-template-columns: auto auto 1fr
  }

  .gh-head-brand {
    align-items: center;
    display: flex;
    height: 40px;
    max-width: 400px;
    word-break: break-all
  }

  .gh-head-logo {
    color: inherit;
    display: block;
    font-size: 2.6em;
    font-weight: 800;
    letter-spacing: -.02em
  }

  .gh-head-logo.no-image {
    margin-top: -2px
  }

  .has-cover .gh-head-logo {
    color: #fff
  }

  .no-logo .gh-head-logo {
    display: none
  }

  .gh-head-logo img {
    max-height: 26px
  }

  .gh-head-brand-wrapper,.gh-head-menu {
    align-items: center;
    display: flex
  }

  .gh-head-menu {
    font-weight: 500;
    margin-top: 1px
  }

  .no-logo .gh-head-menu {
    margin-left: -40px
  }

  .gh-head-menu .nav {
    align-items: center;
    display: inline-flex;
    flex-wrap: wrap;
    gap: 32px;
    list-style: none;
    margin: 0;
    padding: 0
  }

  .gh-head-menu .nav li {
    margin: 0;
    padding: 0
  }

  .gh-head-menu .nav a {
    color: inherit;
    display: inline-block;
    line-height: 1.7
  }

  .gh-head-menu .nav a:hover {
    opacity: .9
  }

  .gh-social {
    align-items: center;
    display: flex;
    gap: 20px
  }

  .gh-social-link {
    color: inherit;
    line-height: 0
  }

  .gh-social-link:hover {
    opacity: .9
  }

  .gh-social-link svg {
    height: 18px;
    width: 18px
  }

  .gh-head-actions {
    align-items: center;
    display: flex;
    gap: 24px;
    justify-content: flex-end;
    list-style: none;
    text-align: right
  }

  .gh-head-button {
    align-items: center;
    background: var(--ghost-accent-color);
    border-radius: 6px;
    color: #fff;
    display: inline-flex;
    font-size: 1.5em;
    font-weight: 600;
    height: 40px;
    letter-spacing: -.005em;
    padding: 8px 16px
  }

  .has-cover .gh-head-button {
    background: #fff;
    color: var(--color-darkgrey)
  }

  .gh-search {
    align-items: center;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    display: inline-flex;
    height: 32px;
    justify-content: center;
    outline: none;
    padding: 0;
    width: 32px
  }

  .gh-search:hover {
    opacity: .9
  }

  .gh-head-brand .gh-search {
    margin-right: 8px
  }

  .gh-head-actions .gh-search {
    margin-right: -10px
  }

  @media (max-width: 991px) {
    .gh-head-actions .gh-search {
      display:none
    }
  }

  @media (min-width: 992px) {
    .gh-head-brand .gh-search {
      display:none
    }
  }

  .gh-burger {
    cursor: pointer;
    display: none;
    position: relative
  }

  .gh-burger-box {
    align-items: center;
    display: flex;
    height: 33px;
    justify-content: center;
    position: relative;
    width: 33px
  }

  .has-cover .gh-burger-box {
    color: #fff
  }

  .gh-burger-inner {
    height: 100%;
    width: 100%
  }

  .gh-burger-box:before {
    transition: transform .3s cubic-bezier(.2,.6,.3,1),width .3s cubic-bezier(.2,.6,.3,1)
  }

  .gh-burger-box:before,.gh-burger-inner:after,.gh-burger-inner:before {
    background: currentcolor;
    bottom: 0;
    content: "";
    display: block;
    height: 1px;
    left: 0;
    margin: auto;
    position: absolute;
    top: 0;
    width: 100%;
    will-change: transform,width
  }

  .gh-burger-inner:after,.gh-burger-inner:before {
    transition: transform .25s cubic-bezier(.2,.7,.3,1),width .25s cubic-bezier(.2,.7,.3,1)
  }

  .gh-burger-inner:before {
    transform: translatey(-6px)
  }

  .gh-burger-inner:after {
    transform: translatey(6px)
  }

  body:not(.gh-head-open) .gh-burger:hover .gh-burger-inner:before {
    transform: translatey(-8px)
  }

  body:not(.gh-head-open) .gh-burger:hover .gh-burger-inner:after {
    transform: translatey(8px)
  }

  .gh-head-open .gh-burger-box:before {
    transform: translatex(19px);
    transition: transform .2s cubic-bezier(.2,.7,.3,1),width .2s cubic-bezier(.2,.7,.3,1);
    width: 0
  }

  .gh-head-open .gh-burger-inner:before {
    transform: translatex(6px) rotate(135deg);
    width: 26px
  }

  .gh-head-open .gh-burger-inner:after {
    transform: translatex(6px) rotate(-135deg);
    width: 26px
  }

  @media (max-width: 991px) {
    .gh-burger {
      display:inline-block
    }

    #gh-head {
      overflow: hidden
    }

    #gh-head .gh-head-inner {
      grid-template-columns: 1fr;
      height: 100%
    }

    #gh-head .gh-head-brand {
      align-items: center;
      display: flex;
      grid-column-start: auto;
      justify-content: space-between;
      max-width: none;
      position: relative;
      -webkit-user-select: none;
      -moz-user-select: none;
      user-select: none;
      z-index: 10
    }

    .no-logo #gh-head .gh-head-brand {
      justify-content: flex-end
    }

    #gh-head .gh-head-menu {
      align-self: center;
      font-size: 3.6em;
      font-weight: 300;
      line-height: 1.1em;
      margin: 0 0 10vh;
      text-align: center
    }

    #gh-head .gh-head-menu,#gh-head .gh-head-menu .nav {
      align-items: center;
      display: flex;
      flex-direction: column
    }

    #gh-head .gh-head-menu .nav {
      font-weight: 500;
      gap: 16px
    }

    #gh-head .gh-head-actions {
      flex-direction: column-reverse;
      justify-content: center;
      padding: 20px 0 32px;
      text-align: left
    }

    #gh-head .gh-head-actions,#gh-head .gh-head-menu {
      display: none
    }

    .gh-head-open {
      height: 100vh;
      overflow: hidden
    }

    .gh-head-open #gh-head {
      bottom: 0;
      left: 0;
      overflow-y: scroll;
      position: fixed;
      right: 0;
      top: 0;
      z-index: 3999999
    }

    .gh-head-open #gh-head .gh-head-inner {
      grid-template-rows: auto 1fr auto
    }

    .gh-head-open #gh-head .gh-head-actions,.gh-head-open #gh-head .gh-head-menu {
      display: flex
    }
  }

  @media (max-width: 600px) {
    #gh-head .gh-head-menu {
      font-size:6vmin
    }
  }

  .has-cover.gh-head-open .gh-head {
    background: var(--ghost-accent-color)
  }

  .post-feed {
    display: grid;
    gap: 4.8vmin 4vmin;
    grid-template-columns: repeat(6,1fr);
    padding: max(4.8vmin,36px) 0 0;
    position: relative
  }

  :is(.tag-template,.author-template) .post-feed {
    margin-top: 4vmin
  }

  @media (max-width: 991px) {
    .post-feed {
      grid-template-columns:1fr 1fr
    }
  }

  @media (max-width: 767px) {
    .post-feed {
      grid-gap:40px;
      grid-template-columns: 1fr
    }
  }

  .post-card {
    background-size: cover;
    display: flex;
    flex-direction: column;
    grid-column: span 2;
    position: relative;
    word-break: break-word
  }

  .post-card-image-link {
    display: block;
    margin-bottom: 32px;
    overflow: hidden;
    position: relative
  }

  .post-card-image-link:after {
    content: "";
    display: block;
    padding-bottom: 55%
  }

  .post-card[class*=post-access-] .post-card-image-link:after {
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px);
    background-color: rgba(0,0,0,.5)
  }

  .post-card.keep-ratio[class*=post-access-] .post-card-image-link:after {
    inset: 0;
    padding-bottom: 0;
    position: absolute
  }

  .post-card.keep-ratio:not(.post-card-large):not(.post-card-full) .post-card-image-link:after {
    padding-bottom: 0
  }

  .post-card-image {
    background: var(--color-lightgrey) no-repeat center center;
    height: 100%;
    inset: 0;
    -o-object-fit: cover;
    object-fit: cover;
    position: absolute;
    width: 100%
  }

  .post-card.keep-ratio:not(.post-card-large):not(.post-card-full) .post-card-image {
    position: static
  }

  .post-card-access {
    align-items: center;
    color: #fff;
    display: flex;
    font-size: 1.5em;
    font-weight: 600;
    gap: 4px;
    inset: 0;
    justify-content: center;
    position: absolute;
    z-index: 10
  }

  .post-card-content-link {
    color: var(--color-darkgrey);
    display: block;
    position: relative
  }

  .post-card-content-link:hover {
    text-decoration: none
  }

  .post-feed .no-image .post-card-content-link {
    padding: 0
  }

  .no-image .post-card-header {
    margin-top: 0
  }

  .post-card-tags {
    align-items: center;
    color: var(--color-secondary-text);
    display: flex;
    font-size: 1.4em;
    font-weight: 600;
    gap: 12px;
    letter-spacing: -.005em;
    line-height: 1;
    margin: 0 0 10px
  }

  .post-card-featured {
    align-items: center;
    color: var(--ghost-accent-color);
    display: flex;
    gap: 3px;
    padding-left: 18px;
    position: relative
  }

  .post-card-featured svg {
    left: 0;
    position: absolute
  }

  .post-card-title {
    font-size: 2.6em;
    font-weight: 800;
    margin: 0
  }

  .post-card-content-link:hover .post-card-title {
    opacity: .9
  }

  .no-image .post-card-title {
    margin-top: 0
  }

  .has-serif-title .post-card-title {
    font-family: var(--font-serif);
    letter-spacing: -.005em
  }

  .post-card-title svg {
    margin-left: -1px;
    margin-top: -3px
  }

  .post-card-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1
  }

  .post-card-excerpt {
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    font-size: 1.6em;
    line-height: 1.5;
    margin-top: 12px;
    max-width: 720px;
    overflow-y: hidden;
    word-break: break-word
  }

  .has-sans-body .post-card-excerpt {
    font-family: var(--font-sans)
  }

  .post-card:not(.post-card-large):not(.post-card-full):not(.dynamic):not(.no-image) .post-card-excerpt {
    -webkit-line-clamp: 2
  }

  :is(.tag-template,.author-template) .post-card-excerpt {
    margin-top: 6px
  }

  .post-card-meta {
    align-items: center;
    color: var(--color-secondary-text);
    display: flex;
    font-size: 1.3em;
    margin-top: 12px;
    padding: 0
  }

  .post-card-meta .sep {
    margin: 0 4px
  }

  .author-profile-image {
    background: #fff;
    border-radius: 100%;
    display: block;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%
  }

  .author-list {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0 0 0 4px;
    padding: 0
  }

  .author-list-item {
    flex-shrink: 0;
    margin: 0;
    padding: 0;
    position: relative
  }

  @media (min-width: 1001px) {
    .post-card-large {
      grid-gap:4vmin;
      border-top: 0;
      display: grid;
      grid-column: span 6;
      grid-template-columns: repeat(3,1fr)
    }

    .post-card-large:not(.no-image) .post-card-header {
      margin-top: 0
    }

    .post-card-large .post-card-image-link {
      grid-column: span 2;
      margin-bottom: 0;
      position: relative
    }

    .post-card-large .post-card-content {
      grid-column: span 1
    }

    .post-card-large.no-image .post-card-content {
      grid-column: span 2
    }

    .post-card-large .post-card-image {
      height: 100%;
      position: absolute;
      width: 100%
    }

    .post-card-large .post-card-tags {
      margin-bottom: 12px
    }

    .post-card-large .post-card-title {
      font-size: 4.4em;
      line-height: 1;
      margin-top: 0
    }

    .post-card-large .post-card-excerpt {
      margin-top: 16px
    }

    .post-card-full {
      grid-column: span 6
    }

    .post-card-full .post-card-image-link {
      margin-bottom: 40px
    }

    .post-card-full .post-card-tags {
      margin-bottom: 14px
    }

    .post-card-full .post-card-title {
      font-size: 6.4em;
      line-height: .95
    }

    .post-card-full .post-card-excerpt {
      font-size: 1.8em;
      margin-top: 20px
    }

    .post-card-large+.post-card-large:nth-child(2n) {
      margin: 32px 0
    }

    .post-card-large+.post-card-large:nth-child(2n) .post-card-content {
      order: -1
    }

    .post-card.dynamic {
      grid-column: span 3
    }

    .post-card.dynamic .post-card-title {
      font-size: 3em
    }
  }

  .article {
    padding: max(8vmin,40px) 0 max(8vmin,64px);
    word-break: break-word;

    // Draw io integration (fixes https://gitlab.com/triarc-labs/glb/leistungserfassung/-/issues/2337)
    .mxgraph {
      word-break: normal;
    }
  }

  .page-template .article {
    padding-top: max(12vmin,64px)
  }

  .article-header {
    padding: 0 0 max(6.4vmin,40px)
  }

  .page-template .article-header {
    padding-bottom: max(3.2vmin,28px)
  }

  .article-tag {
    font-size: 1.6em;
    margin-bottom: 16px
  }

  .article-tag a {
    color: var(--color-secondary-text)
  }

  .article-title {
    color: var(--color-darkgrey);
    font-size: clamp(3.2em,5vw,5.2em);
    font-weight: 800;
    line-height: 1;
    margin-bottom: 0
  }

  .has-serif-title .article-title {
    font-family: var(--font-serif)
  }

  .article-excerpt {
    color: var(--color-darkgrey);
    font-size: 2em;
    line-height: 1.45;
    margin-top: 20px;
    max-width: 720px
  }

  .gh-canvas .article-image {
    grid-column: wide-start/wide-end;
    margin: max(6.4vmin,40px) 0 0;
    width: 100%
  }

  .image-full .article-image {
    grid-column: full-start/full-end
  }

  .image-small .article-image {
    grid-column: main-start/main-end
  }

  .gh-canvas .article-image img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%
  }

  @media (max-width: 767px) {
    .article-excerpt {
      font-size:1.7em;
      margin-top: 14px
    }
  }

  .gh-canvas {
    display: grid;
    grid-template-columns: [full-start] minmax(max(4vmin,20px),auto) [wide-start] minmax(auto,240px) [main-start] min(720px,calc(100% - max(8vmin, 40px))) [main-end] minmax(auto,240px) [wide-end] minmax(max(4vmin,20px),auto) [full-end]
  }

  .gh-canvas>* {
    grid-column: main-start/main-end
  }

  .kg-width-wide {
    grid-column: wide-start/wide-end
  }

  .kg-width-full {
    grid-column: full-start/full-end
  }

  .kg-width-full img {
    width: 100%
  }

  .gh-content>*+* {
    margin-bottom: 0;
    margin-top: max(3.2vmin,24px)
  }

  .gh-content>[id] {
    color: var(--color-darkgrey);
    margin: 0
  }

  .has-serif-title .gh-content>[id] {
    font-family: var(--font-serif)
  }

  .gh-content>[id]:not(:first-child) {
    margin: 2em 0 0
  }

  .gh-content>[id]+* {
    margin-top: 1.5em!important
  }

  .gh-content>blockquote,.gh-content>hr {
    margin-top: max(4.8vmin,32px);
    position: relative
  }

  .gh-content>blockquote+*,.gh-content>hr+* {
    margin-top: max(4.8vmin,32px)!important
  }

  .gh-content a {
    color: var(--ghost-accent-color);
    text-decoration: underline;
    word-break: break-word
  }

  .gh-content>blockquote:not([class]),.gh-content>dl,.gh-content>ol,.gh-content>p,.gh-content>ul {
    font-family: var(--font-serif);
    font-size: 2em;
    font-weight: 400;
    line-height: 1.6em
  }

  .gh-content .kg-callout-card .kg-callout-text,.gh-content .kg-toggle-card .kg-toggle-content>ol,.gh-content .kg-toggle-card .kg-toggle-content>p,.gh-content .kg-toggle-card .kg-toggle-content>ul {
    font-family: var(--font-serif);
    font-size: 1.9em;
    font-weight: 400;
    line-height: 1.6em
  }

  .gh-content .kg-product-card .kg-product-card-description>ol,.gh-content .kg-product-card .kg-product-card-description>p,.gh-content .kg-product-card .kg-product-card-description>ul {
    font-size: 1.7em;
    line-height: 1.6em
  }

  .gh-content .kg-callout-card .kg-callout-emoji {
    font-size: 2.1em;
    line-height: 1.4em
  }

  .gh-content .kg-toggle-card .kg-toggle-heading-text {
    font-size: 2em
  }

  .has-sans-body .gh-content .kg-callout-card .kg-callout-text,.has-sans-body .gh-content .kg-toggle-card .kg-toggle-content>ol,.has-sans-body .gh-content .kg-toggle-card .kg-toggle-content>p,.has-sans-body .gh-content .kg-toggle-card .kg-toggle-content>ul,.has-sans-body .gh-content>blockquote,.has-sans-body .gh-content>dl,.has-sans-body .gh-content>ol,.has-sans-body .gh-content>p,.has-sans-body .gh-content>ul {
    font-family: var(--font-sans)
  }

  .gh-content .kg-product-card .kg-product-card-description>ol,.gh-content .kg-product-card .kg-product-card-description>ul,.gh-content .kg-toggle-card .kg-toggle-content>ol,.gh-content .kg-toggle-card .kg-toggle-content>ul,.gh-content>dl,.gh-content>ol,.gh-content>ul {
    padding-left: 1.9em
  }

  .gh-content>blockquote:not([class]) {
    font-style: italic;
    padding: 0;
    position: relative
  }

  .gh-content>blockquote:not([class]):before {
    background: var(--ghost-accent-color);
    bottom: 0;
    content: "";
    left: -1.5em;
    position: absolute;
    top: 0;
    width: .3em
  }

  .gh-content :not(pre)>code {
    background: #f0f6f9;
    border: 1px solid #e1eaef;
    border-radius: .25em;
    color: #15171a;
    font-size: .9em;
    font-weight: 400!important;
    line-height: 1em;
    padding: .15em .4em;
    vertical-align: middle
  }

  .gh-content pre {
    background: var(--color-darkgrey);
    border-radius: 5px;
    box-shadow: 0 2px 6px -2px rgba(0,0,0,.1),0 0 1px rgba(0,0,0,.4);
    color: var(--color-wash);
    font-size: 1.4em;
    line-height: 1.5em;
    overflow: auto;
    padding: 16px 20px
  }

  @media (max-width: 650px) {
    .gh-content .kg-callout-card .kg-callout-text,.gh-content .kg-toggle-card .kg-toggle-content>ol,.gh-content .kg-toggle-card .kg-toggle-content>p,.gh-content .kg-toggle-card .kg-toggle-content>ul,.gh-content>blockquote:not([class]),.gh-content>dl,.gh-content>ol,.gh-content>p,.gh-content>ul {
      font-size:1.8em
    }

    .gh-content .kg-product-card .kg-product-card-description>ol,.gh-content .kg-product-card .kg-product-card-description>p,.gh-content .kg-product-card .kg-product-card-description>ul {
      font-size: 1.6em
    }

    .gh-content blockquote:not([class]):before {
      left: min(-4vmin,-20px)
    }
  }

  .gh-content .kg-card+:not(.kg-card),.gh-content :not(.kg-card):not([id])+.kg-card {
    margin-bottom: 0;
    margin-top: 6vmin
  }

  .kg-embed-card {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%
  }

  .kg-image-card img {
    margin: auto
  }

  .has-serif-title .kg-toggle-card .kg-toggle-heading-text {
    font-family: var(--font-serif)
  }

  .gh-content .kg-callout-card-accent a {
    text-decoration: underline
  }

  .kg-blockquote-alt {
    color: var(--color-midgrey);
    font-family: var(--font-serif)
  }

  .has-sans-body .kg-blockquote-alt {
    font-family: var(--font-sans)
  }

  .kg-card.kg-header-card.kg-style-dark {
    background: var(--color-darkgrey)
  }

  .kg-header-card.kg-style-light h2.kg-header-card-header {
    color: #0a0b0c
  }

  .has-serif-title .kg-header-card h2.kg-header-card-header {
    font-family: var(--font-serif)
  }

  figcaption {
    color: rgba(0,0,0,.5);
    font-size: 1.3em;
    line-height: 1.4em;
    padding: 1.5em 1.5em 0;
    text-align: center
  }

  figcaption strong {
    color: rgba(0,0,0,.8)
  }

  figcaption a {
    text-decoration: underline
  }

  iframe.instagram-media {
    margin: 6vmin auto 0!important
  }

  iframe.instagram-media+script+:not([id]) {
    margin-top: 6vmin
  }

  .kg-width-full.kg-card-hascaption {
    display: grid;
    grid-template-columns: inherit
  }

  .kg-width-wide.kg-card-hascaption img {
    grid-column: wide-start/wide-end
  }

  .kg-width-full.kg-card-hascaption img {
    grid-column: 1/-1
  }

  .kg-width-full.kg-card-hascaption figcaption {
    grid-column: main-start/main-end
  }

  .article-comments {
    margin: 6vmin 0 0
  }

  .footnotes-sep {
    margin-bottom: 30px
  }

  .footnotes {
    font-size: 1.5em
  }

  .footnotes p {
    margin: 0
  }

  .footnote-backref {
    box-shadow: none!important;
    font-size: 1.2em;
    font-weight: 700;
    text-decoration: none!important
  }

  .gh-content table {
    -webkit-overflow-scrolling: touch;
    background: radial-gradient(ellipse at left,rgba(0,0,0,.2) 0,transparent 75%) 0,radial-gradient(ellipse at right,rgba(0,0,0,.2) 0,transparent 75%) 100%;
    background-attachment: scroll,scroll;
    background-repeat: no-repeat;
    background-size: 10px 100%,10px 100%;
    border-collapse: collapse;
    border-spacing: 0;
    display: inline-block;
    font-family: var(--font-sans);
    font-size: 1.6em;
    max-width: 100%;
    overflow-x: auto;
    vertical-align: top;
    white-space: nowrap;
    width: auto
  }

  .gh-content table td:first-child {
    background-image: linear-gradient(90deg,#fff 50%,hsla(0,0%,100%,0));
    background-repeat: no-repeat;
    background-size: 20px 100%
  }

  .gh-content table td:last-child {
    background-image: linear-gradient(270deg,#fff 50%,hsla(0,0%,100%,0));
    background-position: 100% 0;
    background-repeat: no-repeat;
    background-size: 20px 100%
  }

  .gh-content table th {
    background-color: #f4f8fb;
    color: var(--color-darkgrey);
    font-size: 1.2em;
    font-weight: 700;
    letter-spacing: .2px;
    text-align: left;
    text-transform: uppercase
  }

  .gh-content table td,.gh-content table th {
    border: 1px solid #e2ecf3;
    padding: 6px 12px
  }

  .article-byline {
    display: flex;
    justify-content: space-between;
    margin: min(24px,5.6vmin) 0 0
  }

  .article-byline-content {
    align-items: center;
    display: flex;
    flex-grow: 1
  }

  .article-byline-content .author-list {
    justify-content: flex-start;
    padding: 0 14px 0 0
  }

  .article-byline-meta {
    color: var(--color-secondary-text);
    font-size: 1.4em;
    line-height: 1.2em
  }

  .article-byline-meta .author-name {
    font-size: 1.7em;
    font-weight: 700;
    letter-spacing: 0;
    margin: 0 0 6px
  }

  .article-byline-meta .bull {
    display: inline-block;
    margin: 0 2px
  }

  .author-avatar {
    background-color: var(--color-border);
    border: 2px solid #fff;
    border-radius: 50%;
    display: block;
    height: min(56px,13.6vmin);
    margin: 0 -4px;
    overflow: hidden;
    width: min(56px,13.6vmin)
  }

  .page-template .article-title {
    margin-bottom: 0
  }

  @media (max-width: 767px) {
    .article-byline-content .author-list {
      padding-right:12px
    }

    .article-byline-meta .author-name {
      margin-bottom: 4px
    }
  }

  .footer-cta {
    position: relative;
    text-align: center
  }

  .footer-cta-title {
    font-size: clamp(2.6em,5vw,3.8em);
    font-weight: 800;
    margin: 0 0 min(24px,6.4vmin)
  }

  .has-serif-title .footer-cta-title {
    font-family: var(--font-serif)
  }

  .footer-cta-button {
    align-items: center;
    background: #fff;
    border: 1px solid var(--color-border);
    border-radius: 8px;
    color: var(--color-secondary-text);
    display: inline-flex;
    font-size: 1.7em;
    justify-content: space-between;
    max-width: 500px;
    padding: 5px 5px 5px 15px;
    position: relative;
    transition: border-color .2s;
    width: 100%
  }

  .footer-cta-button:hover {
    border-color: #c2c2c2
  }

  .footer-cta-button span {
    background: var(--ghost-accent-color);
    border-radius: 6px;
    color: #fff;
    display: inline-block;
    font-size: 1.6em;
    font-weight: 600;
    letter-spacing: -.005em;
    padding: 9px 15px
  }

  .read-more-wrap {
    margin-top: 2.4vmin
  }

  .footer-cta+.read-more-wrap {
    margin-top: max(12vmin,72px)
  }

  .read-more {
    grid-gap: 4vmin;
    display: grid;
    grid-template-columns: repeat(6,1fr)
  }

  .read-more .post-card-tags {
    display: none
  }

  @media (max-width: 1000px) {
    .read-more {
      grid-template-columns:repeat(4,1fr)
    }

    .read-more .post-card:nth-child(3) {
      display: none
    }
  }

  @media (max-width: 700px) {
    .read-more {
      grid-template-columns:repeat(2,1fr)
    }

    .read-more .post-card:nth-child(2) {
      display: none
    }
  }

  .author-profile-pic {
    background: #fff;
    border-radius: 50%;
    display: block;
    height: 80px;
    margin: 0 0 2em;
    -o-object-fit: cover;
    object-fit: cover;
    width: 80px
  }

  .author-profile-footer {
    margin-top: 16px
  }

  .author-profile-location {
    font-weight: 700
  }

  .author-profile-meta {
    display: flex;
    gap: 10px
  }

  .author-profile-social-link {
    color: var(--color-secondary-text);
    font-size: 1.3em
  }

  .author-profile-social-link:hover {
    color: var(--color-darkgrey)
  }

  .author-profile-social-link svg {
    height: 16px;
    width: 16px
  }

  @media (min-width: 1001px) {
    .author-template .post-card-large .post-card-content {
      grid-column:span 2;
      max-width: 640px
    }
  }

  .tag-template .post-card-large .post-card-image-link {
    grid-column: 2/span 2;
    order: 2
  }

  .tag-template .post-card-large .post-card-content {
    order: 1
  }

  @media (min-width: 1001px) {
    .tag-template .post-card-large .post-card-content {
      grid-column:span 2;
      max-width: 640px
    }
  }

  .error-content {
    padding: 14vw 4vw 2vw
  }

  .error-message {
    padding-bottom: 10vw;
    text-align: center
  }

  .error-code {
    font-size: 12vw;
    letter-spacing: -5px;
    line-height: 1em;
    margin: 0
  }

  .error-description {
    color: var(--color-secondary-text);
    font-size: 3.2em;
    font-weight: 400;
    letter-spacing: -.005em;
    line-height: 1.3em;
    margin: 0
  }

  .error-link {
    display: inline-block;
    margin-top: 5px
  }

  @media (min-width: 940px) {
    .error-content .post-card {
      border-bottom:none;
      margin-bottom: 0;
      padding-bottom: 0
    }
  }

  @media (max-width: 800px) {
    .error-content {
      padding-top:24vw
    }

    .error-code {
      font-size: 11.2em
    }

    .error-message {
      padding-bottom: 16vw
    }

    .error-description {
      font-size: 1.8em;
      margin: 5px 0 0
    }
  }

  @media (max-width: 500px) {
    .error-content {
      padding-top:28vw
    }

    .error-message {
      padding-bottom: 14vw
    }
  }

  .site-footer {
    background: #0a0b0c;
    color: #fff;
    margin: max(12vmin,64px) 0 0;
    padding-bottom: 140px;
    padding-top: 48px;
    position: relative
  }

  .site-footer .inner {
    grid-gap: 40px;
    color: hsla(0,0%,100%,.7);
    display: grid;
    font-size: 1.3em;
    grid-template-columns: auto 1fr auto
  }

  .site-footer .copyright a {
    color: #fff;
    font-weight: 500;
    letter-spacing: -.015em
  }

  .site-footer a {
    color: hsla(0,0%,100%,.7)
  }

  .site-footer a:hover {
    color: #fff;
    text-decoration: none
  }

  .site-footer-nav ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    list-style: none;
    margin: 0 0 20px;
    padding: 0
  }

  .site-footer-nav li {
    align-items: center;
    display: inline-flex;
    line-height: 2em;
    margin: 0;
    padding: 0
  }

  .site-footer-nav a {
    align-items: center;
    display: inline-flex;
    margin-left: 10px;
    position: relative
  }

  .site-footer-nav li:not(:first-child) a:before {
    background: #fff;
    border-radius: 100%;
    content: "";
    display: block;
    height: 2px;
    margin: 0 10px 0 0;
    width: 2px
  }

  @media (max-width: 767px) {
    .site-footer .inner {
      grid-gap:0;
      grid-template-columns: 1fr;
      max-width: 500px;
      text-align: center
    }

    .site-footer .copyright,.site-footer .copyright a {
      color: #fff;
      font-size: 1.5em
    }

    .site-footer .copyright {
      margin-bottom: 16px
    }
  }

  html.dark-mode body {
    background: var(--color-darkmode);
    color: hsla(0,0%,100%,.75)
  }

  html.dark-mode img {
    opacity: .9
  }

  html.dark-mode kbd {
    background: #212427
  }

  html.dark-mode figcaption a {
    color: #fff
  }

  html.dark-mode .gh-head {
    background: var(--color-darkmode);
    color: #fff
  }

  html.dark-mode .gh-burger-box,html.dark-mode .site-header-content {
    color: #fff
  }

  html.dark-mode .post-card-image {
    background: var(--color-darkmode)
  }

  html.dark-mode :is(.post-card-tags,.post-card-meta,.article-tag a,.byline-meta-content) {
    color: #5f5f5f
  }

  html.dark-mode .post-card-featured,html.dark-mode .post-card-title {
    color: #fff
  }

  html.dark-mode .post-card-excerpt {
    color: var(--color-secondary-text)
  }

  html.dark-mode .article-title,html.dark-mode .author-profile-location,html.dark-mode .author-profile-social-link:hover {
    color: #fff
  }

  html.dark-mode .article-excerpt {
    color: var(--color-secondary-text)
  }

  html.dark-mode .post-full-image {
    background-color: #282b2f
  }

  html.dark-mode .author-avatar {
    background-color: #282b2f;
    border-color: var(--color-darkmode)
  }

  html.dark-mode .author-profile-image {
    opacity: 1
  }

  html.dark-mode .author-profile-image path {
    fill: var(--color-darkmode)
  }

  html.dark-mode .article-byline-meta .author-name a {
    color: #fff
  }

  html.dark-mode .no-image .author-social-link a {
    color: hsla(0,0%,100%,.75)
  }

  html.dark-mode .gh-content>[id] {
    color: hsla(0,0%,100%,.9)
  }

  html.dark-mode .gh-content pre {
    background: #030303
  }

  html.dark-mode .gh-content :not(pre)>code {
    background: #23262b;
    border-color: #282b2f;
    color: var(--color-wash)
  }

  html.dark-mode .gh-content a:not(.kg-btn):not(.kg-nft-card-container):not(.kg-product-card-button):not(.kg-header-card-button),html.dark-mode .gh-content em,html.dark-mode .gh-content strong {
    color: #fff
  }

  html.dark-mode .gh-content code {
    background: #000;
    color: #fff
  }

  html.dark-mode hr {
    border-top-color: #282b2f
  }

  html.dark-mode .gh-content hr:after {
    background: #282b2f;
    box-shadow: var(--color-darkmode) 0 0 0 5px
  }

  html.dark-mode figcaption {
    color: hsla(0,0%,100%,.6)
  }

  html.dark-mode .gh-content table td:first-child {
    background-image: linear-gradient(to right,var(--color-darkmode) 50%,rgba(21,23,25,0) 100%)
  }

  html.dark-mode .gh-content table td:last-child {
    background-image: linear-gradient(to left,var(--color-darkmode) 50%,rgba(21,23,25,0) 100%)
  }

  html.dark-mode .gh-content table th {
    background-color: #282b2f;
    color: hsla(0,0%,100%,.85)
  }

  html.dark-mode .gh-content table td,html.dark-mode .gh-content table th {
    border: 1px solid #282b2f
  }

  html.dark-mode .gh-content input {
    color: #303a3e
  }

  html.dark-mode .site-archive-header .no-image {
    background: var(--color-darkmode);
    color: hsla(0,0%,100%,.9)
  }

  html.dark-mode .kg-header-card.kg-style-dark {
    background: #0a0b0c
  }

  html.dark-mode .kg-header-card.kg-style-light {
    background: #202328
  }

  html.dark-mode .footer-cta-title,html.dark-mode .kg-header-card h2.kg-header-card-header,html.dark-mode .kg-header-card h3.kg-header-card-subheader {
    color: #fff
  }

  @media (prefers-color-scheme: dark) {
    html.auto-color body {
      background:var(--color-darkmode);
      color: hsla(0,0%,100%,.75)
    }

    html.auto-color img {
      opacity: .9
    }

    html.auto-color kbd {
      background: #212427
    }

    html.auto-color figcaption a {
      color: #fff
    }

    html.auto-color .gh-head {
      background: var(--color-darkmode);
      color: #fff
    }

    html.auto-color .gh-burger-box,html.auto-color .site-header-content {
      color: #fff
    }

    html.auto-color .post-card-image {
      background: var(--color-darkmode)
    }

    html.auto-color :is(.post-card-tags,.post-card-meta,.article-tag a,.byline-meta-content) {
      color: #5f5f5f
    }

    html.auto-color .post-card-featured,html.auto-color .post-card-title {
      color: #fff
    }

    html.auto-color .post-card-excerpt {
      color: var(--color-secondary-text)
    }

    html.auto-color .article-title,html.auto-color .author-profile-location,html.auto-color .author-profile-social-link:hover {
      color: #fff
    }

    html.auto-color .article-excerpt {
      color: var(--color-secondary-text)
    }

    html.auto-color .post-full-image {
      background-color: #282b2f
    }

    html.auto-color .author-avatar {
      background-color: #282b2f;
      border-color: var(--color-darkmode)
    }

    html.auto-color .author-profile-image {
      opacity: 1
    }

    html.auto-color .author-profile-image path {
      fill: var(--color-darkmode)
    }

    html.auto-color .article-byline-meta .author-name a {
      color: #fff
    }

    html.auto-color .no-image .author-social-link a {
      color: hsla(0,0%,100%,.75)
    }

    html.auto-color .gh-content>[id] {
      color: hsla(0,0%,100%,.9)
    }

    html.auto-color .gh-content pre {
      background: #030303
    }

    html.auto-color .gh-content :not(pre)>code {
      background: #23262b;
      border-color: #282b2f;
      color: var(--color-wash)
    }

    html.auto-color .gh-content a:not(.kg-btn):not(.kg-nft-card-container):not(.kg-product-card-button):not(.kg-header-card-button),html.auto-color .gh-content em,html.auto-color .gh-content strong {
      color: #fff
    }

    html.auto-color .gh-content code {
      background: #000;
      color: #fff
    }

    html.auto-color hr {
      border-top-color: #282b2f
    }

    html.auto-color .gh-content hr:after {
      background: #282b2f;
      box-shadow: var(--color-darkmode) 0 0 0 5px
    }

    html.auto-color figcaption {
      color: hsla(0,0%,100%,.6)
    }

    html.auto-color .gh-content table td:first-child {
      background-image: linear-gradient(to right,var(--color-darkmode) 50%,rgba(21,23,25,0) 100%)
    }

    html.auto-color .gh-content table td:last-child {
      background-image: linear-gradient(to left,var(--color-darkmode) 50%,rgba(21,23,25,0) 100%)
    }

    html.auto-color .gh-content table th {
      background-color: #282b2f;
      color: hsla(0,0%,100%,.85)
    }

    html.auto-color .gh-content table td,html.auto-color .gh-content table th {
      border: 1px solid #282b2f
    }

    html.auto-color .gh-content input {
      color: #303a3e
    }

    html.auto-color .site-archive-header .no-image {
      background: var(--color-darkmode);
      color: hsla(0,0%,100%,.9)
    }

    html.auto-color .kg-header-card.kg-style-dark {
      background: #0a0b0c
    }

    html.auto-color .kg-header-card.kg-style-light {
      background: #202328
    }

    html.auto-color .footer-cta-title,html.auto-color .kg-header-card h2.kg-header-card-header,html.auto-color .kg-header-card h3.kg-header-card-subheader {
      color: #fff
    }
  }
  /*# sourceMappingURL=screen.css.map */
}
