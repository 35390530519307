// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #104f3a;
  --ion-color-primary-rgb: 16, 79, 58;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: rgb(16, 79, 58);
  --ion-color-primary-tint: rgb(16, 79, 58);

  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --week-overview-height: 70px;


  --ion-color-headerbar: #69bb7b;
  --ion-color-headerbar-rgb: 105, 187, 123;
  --ion-color-headerbar-contrast: #ffffff;
  --ion-color-headerbar-contrast-rgb: 255, 255, 255;
  --ion-color-headerbar-shade: #5ca56c;
  --ion-color-headerbar-tint: #78c288;

  --primary: #104f3a;
  --text-primary: #fff;
  --accent: #5d942c;
  --text-accent: #fff;
  --text-header-bar: 'transparent';

  --toolbar: #104f3a;
  --text-toolbar-inactive: #2d7051;
  --text-toolbar-active: #ff;

  --bg-message: #fcfefe;

  --status-green: rgba(57, 140, 102, 1);
  --status-green-light: rgba(57, 140, 102, 0.2);
  --status-orange: rgba(193, 120, 35, 1);
  --status-orange-light: rgba(193, 120, 35, 0.2);
  --status-red: rgba(193, 50, 35, 1);
  --status-red-light: rgba(193, 50, 35, 0.2);
  --status-not-selected: #808080;
}

.ion-color-headerbar {
  --ion-color-base: var(--ion-color-headerbar);
  --ion-color-base-rgb: var(--ion-color-headerbar-rgb);
  --ion-color-contrast: var(--ion-color-headerbar-contrast);
  --ion-color-contrast-rgb: var(--ion-color-headerbar-contrast-rgb);
  --ion-color-shade: var(--ion-color-headerbar-shade);
  --ion-color-tint: var(--ion-color-headerbar-tint);
}
